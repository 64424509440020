import React from "react"
import SeoComponent from "../components/atoms/SeoComponent"
import ApplicationConfirmationBody from "../components/templates/ApplicationConfirmationBody"
import GlobalTemplate from "../components/templates/GlobalTemplate"

const success = () => {
  return (
    <>
      <GlobalTemplate>
        <ApplicationConfirmationBody statusSuccess={true} />
      </GlobalTemplate>
    </>
  )
}

export default success

export const Head = () => (
  <SeoComponent pageTitle="Blackness in Full Bloom | Application Success" />
)
